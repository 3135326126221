<template>
  <div class="share-note">
    <Spin v-if="isLoading" size="large" fix>
      <div class="loading-text">加载中...</div>
    </Spin>
    <h2 class="note-title">{{ noteTitle }}</h2>
    <div class="note-content" ref="noteContent">
      <MarkdownPreview v-if="noteContent" :initialValue="noteContent" @on-copy="onCopy" />
      <div v-else class="no-note-message">
        <p>笔记不存在</p>
      </div>
    </div>
    <input
  type="file"
  accept=".md"
  ref="fileInput"
  @change="handleFileSelection"
  style="display: none;"
/>

    <ShareBall @open-add-publish="previewLocalFile" @to-top="scrollToTop" @download="download" />
  </div>
</template>

<script>
import { getShareNote } from "@/api/note";
import { MarkdownPreview } from "vue-meditor";
import ShareBall from "@/components/ShareBall.vue";
export default {
  data() {
    return {
      noteTitle: "",
      noteContent: "",
      isLoading: false,
    };
  },
  components: {
    MarkdownPreview,
    ShareBall,
  },
  watch: {
    '$route'(to, from) {
      const shareKey = to.query.shareKey;
      if (shareKey) {
        this.fetchNoteData(shareKey);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
        let shareKey = this.$route.query.shareKey;
        if (!shareKey) {
            // 如果URL中没有shareKey，尝试从sessionStorage获取
            shareKey = sessionStorage.getItem('shareKey');
        }
        if (shareKey) {
            this.fetchNoteData(shareKey);
        }
    });
  },
  methods: {
    download() {
      if (!this.noteContent) {
        this.$Message.warning("没有内容可下载");
        return;
      }
      const blob = new Blob([this.noteContent], { type: 'text/markdown;charset=utf-8' });
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = this.noteTitle + '.md';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(downloadUrl);
      this.$Message.success("下载成功");
    },
    handleFileSelection(event) {
    const file = event.target.files[0];
    if (file) {
      this.isLoading = true;
      this.noteTitle = file.name.replace(/\.[^/.]+$/, "");
      const reader = new FileReader();
      reader.onload = (e) => {
        this.noteContent = e.target.result; // 将文件内容作为 noteContent
        this.isLoading = false;
        this.$Message.success("文件已加载");
      };
      reader.onerror = () => {
        this.isLoading = false;
        this.$Message.error("文件读取失败");
      };
      reader.readAsText(file, "utf-8"); // 按文本读取文件内容
    } else {
      this.$Message.warning("未选择文件");
    }
  },
    previewLocalFile() {
      console.log("预览本地文件");
      this.$refs.fileInput.click();
    },
    onCopy(val) {
      navigator.clipboard.writeText(val)
        .then(() => {
          this.$Message.success("复制成功");
        })
        .catch((err) => {
          this.$Message.error("复制失败");
          console.error("复制失败：", err);
        });
    },
    fetchNoteData(shareKey) {
      getShareNote({ shareKey: shareKey })
        .then(response => {
          if (response.code !== 0) {
            this.$Message.error("获取笔记失败:" + response.msg);
          } else {
            this.noteTitle = response.retVal.noteTitle;
            this.noteContent = response.retVal.noteContent;
          }
        })
        .catch(err => {
          this.$Message.error("获取笔记失败");
        });
    },
    scrollToTop() {
      this.$refs.noteContent.scrollTo({ top: 0, behavior: 'smooth' }); // 平滑滚动到内容顶部
    },
  },
};
</script>

<style scoped>
.share-note {
  width: 100%;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: #333;
}

.note-title {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  border-bottom: 2px solid #4CAF50;
  padding-bottom: 10px;
  font-family: 'Roboto', sans-serif;
}

.note-content {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  min-height: 200px;
  max-height: 80vh;
  overflow-y: auto; /* 允许滚动 */
  margin: 20px;
}

.no-note-message {
  text-align: center;
  color: #888;
  font-size: 20px;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
}

.floating-buttons {
  position: fixed;
  bottom: 30px; /* 增加与底部的距离 */
  right: 30px; /* 增加与右侧的距离 */
  display: flex;
  flex-direction: column;
  gap: 10px; /* 按钮之间的间距 */
  z-index: 1000; /* 提高层级以确保可见性 */
}

.floating-button {
  width: 50px; /* 减小按钮大小 */
  height: 50px; /* 减小按钮大小 */
  border-radius: 50%; /* 完全圆形 */
  background-color: #4CAF50; /* 基础颜色 */
  background-image: linear-gradient(145deg, #4CAF50, #66bb6a); /* 温和的绿色渐变 */
  color: white;
  font-size: 24px; /* 调整字体大小 */
  cursor: pointer;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2); /* 更柔和的阴影 */
  transition: all 0.3s ease; /* 平滑过渡效果 */
}

.floating-button:hover {
  background-color: #388E3C;
  background-image: linear-gradient(145deg, #388E3C, #4CAF50);
  transform: translateY(-2px); /* 更微的移动效果 */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* 加深阴影 */
}

.loading-text {
  color: #fff;
  font-size: 16px;
}
</style>
