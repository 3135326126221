<template>
    <div class="floating-ball"
         :style="{ top: top + 'px', left: left + 'px' }"
         @mousedown="startDrag"
         @mouseup="stopDrag"
         @click="toggleMenu"
    >
      <div class="menu" :class="{ open: showMenu }" @click.stop="">
        <ul @click.stop="">
          <li @click="publish"><Icon type="md-add" size="24"/> 预览本地文件</li>
          <li @click="goTop"><Icon type="md-arrow-round-up" size="24" />顶部</li>
          <li @click="download"><Icon type="md-download" size="24"/> 下载文件</li>
        </ul>
      </div>
    </div>
  </template>

<script>
export default {
    data() {
        return {
            top: window.innerHeight - 200, // 设置为窗口高度减去悬浮球高度
            left: window.innerWidth - 200, // 设置为窗口宽度减去悬浮球宽度
            showMenu: false,
            dragging: false,
            mouseX: 0,
            mouseY: 0,
        }
    },
    methods: {
        publish() {
            this.$emit('open-add-publish', this.publish)
        },
        goTop() {
            this.$emit('to-top', this.goTop)
        },
        toggleMenu() {
            this.showMenu = !this.showMenu
            if (this.showMenu) {
                document.addEventListener('click', this.closeMenu)
            } else {
                document.removeEventListener('click', this.closeMenu)
            }
        },
        startDrag(e) {
            this.dragging = true
            this.mouseX = e.clientX - this.left
            this.mouseY = e.clientY - this.top
            document.addEventListener('mousemove', this.drag)
        },
        stopDrag() {
            this.dragging = false
            document.removeEventListener('mousemove', this.drag)
        },
        drag(e) {
            if (this.dragging) {
                this.top = e.clientY - this.mouseY
                this.left = e.clientX - this.mouseX
            }
        },
        closeMenu(e) {
            if (!this.$el.contains(e.target)) {
                this.showMenu = false
                document.removeEventListener('click', this.closeMenu)
            }
        },
        handleResize() {
            const boundingRect = this.$el.getBoundingClientRect()
            const windowWidth = window.innerWidth
            const windowHeight = window.innerHeight
            const margin = 30 // 贴边的边距

            if (this.left < margin) {
                this.left = margin
            } else if (this.left > windowWidth - boundingRect.width - margin) {
                this.left = windowWidth - boundingRect.width - margin
            }

            if (this.top < margin) {
                this.top = margin
            } else if (this.top > windowHeight - boundingRect.height - margin) {
                this.top = windowHeight - boundingRect.height - margin
            }
        },
        download() {
            this.$emit('download', this.download)
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize)
    },
}
</script>

  <style scoped>
 .floating-ball {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px; /* 调整宽度 */
  height: 50px; /* 调整高度 */
  border-radius: 50%;
  background-color: #007bff;
  background-image: url('../assets/imgs/menu.png');
  background-size: cover;
  color: #fff;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1); /* 调整阴影大小 */
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 更柔和的阴影 */
  transition: transform 0.3s, box-shadow 0.3s; /* 平滑的转换效果 */
}
  .floating-ball:hover {
    transform: scale(1.1); /* 稍微放大 */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* 悬停时的阴影效果 */
  }

  .menu {
    position: absolute;
    bottom: 60px;
    right: -80px;
    background-color: #333;
    color: #fff;
    width: 180px;
    display: none;
    opacity: 0;
    padding: 8px;
    border-radius: 5px;
    transition: right 0.3s, opacity 0.3s;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    background-color: #ffffff; /* 更亮的背景色 */
  color: #333; /* 文本颜色 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 一致的阴影样式 */
  border: none; /* 去除边框 */
  overflow: hidden; /* 隐藏溢出的子元素 */
  }

  .menu.open {
    right: 55px;
    display: block;
    opacity: 1;
  }

  .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .menu ul li {
    padding: 8px;
    cursor: pointer;
    padding-left: 26px;
    position: relative;
    transition: background-color 0.3s;
    color: #333; /* 菜单项文本颜色 */
  transition: background-color 0.3s, color 0.3s; /* 平滑的颜色变化 */
  }

  .menu ul li:hover {
  background-color: #f8f9fa; /* 悬停时的背景颜色 */
  color: #007bff; /* 悬停时的文本颜色 */
}

  .menu ul li i {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 4px;
    color: #007bff; /* 图标颜色 */
  }
  </style>
